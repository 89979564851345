import React, { lazy } from 'react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter
} from "react-router-dom";
// import Home from '../pages/Home/Home';
import Layout from '../pages/Layout/Layout';
import Upload from '../pages/Upload';
import AuthProvider from '../providers/AuthProvider';
import ProtectedRoute from './ProtectedRoute';
import SmallPdf from '../pages/Upload/SmallPdf';
// import Pic from '../pages/PIC/Pic';
import { useSelector } from 'react-redux';

const Home = lazy(() => import("../pages/Home/Home"));
const Pic = lazy(() => import("../pages/PIC/Pic"));
function Initialize() {
  // const { user, isAuthenticated } = useSelector((state) => state.auth);

  const isAuth = localStorage.getItem('PIC') == null ? false : true;
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <AuthProvider>
            <Layout />
          </AuthProvider>
        </ProtectedRoute>
      ), // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/2pdf",
          element: <Upload />,
        },
        {
          path: "/small-pdf",
          element: <SmallPdf />,
        },
        {
          path: "*",
          element: <Home />,
        },
        {
          path: "",
          element: <Home />,
        },

      ],
    },
  ]

  const routesForNotAuthenticatedOnly = [
    {
      path: "/",
      element: (
        <AuthProvider>
          <Layout />
        </AuthProvider>
      ),
      children: [
        {
          path: "/login",
          element: <Pic />,
        },
        {
          path: "*",
          element: <Navigate to="/login" />,
        },
        {
          path: "",
          element: <Navigate to="/login" />,
        },
      ],
    },
  ];

  const router = createBrowserRouter([
    ...(!isAuth ? routesForNotAuthenticatedOnly : []),
    ...(isAuth ? routesForAuthenticatedOnly : []),
  ]);

  return <RouterProvider router={router} />;
}


export default Initialize
