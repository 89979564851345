import { createSlice, current } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
const authSlice = createSlice({
    name: "auth",
    initialState: {
        isAuthenticated: false,
        user: false,
    },
    reducers: {
        setLogin(state, action) {
            const pic = action?.payload
            const user = { name: pic.name, id: pic.value }
            localStorage.setItem('PIC', JSON.stringify(user))
            state.isAuthenticated = true;
            state.user = user
        },
        setAuthenticated(state, action) {
            console.log(localStorage.getItem("PIC"));
            state.isAuthenticated = localStorage.getItem("PIC") == null ? false : true;
            const localData = localStorage.getItem("PIC") == null ? false : JSON.parse(localStorage.getItem("PIC"))
            state.user = { name: localData?.name, id: localData?.id }
        },
        setLogout(state) {

            state.isAuthenticated = false;
            state.user = false
            localStorage.removeItem('PIC')

        },
    },
});

export const authActions = authSlice.actions;
export default authSlice;
