import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Initialize from "./routes/initialize";
import { Provider } from "react-redux";
import store from "./store";
// import ReactGA from "react-ga4";

// ReactGA.initialize("G-R2465JBVQY");

// ReactGA.send({
//   hitType: "pageView",
//   page: window.location.pathname
// })

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient({
  // Config
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // refetchOnMount: false,
      // refetchOnReconnect: false,
      // retry: false,
      // staleTime: 1000 * 60 * 60 * 24,
    },
  },
});
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Initialize />
    </QueryClientProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
