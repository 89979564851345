import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from 'react-redux';
function Layout() {
    const [isLoaded, setIsLoaded] = useState(true);
    const dispatch = useDispatch();
    const location = useLocation();
    const { hash, pathname, search } = location;

    useEffect(() => {
        setTimeout(() => {
            setIsLoaded(false);
        }, 300);
    }, []);

    const Loading = () => {
        return <div className='flex h-screen justify-center items-center  ' >
            <CircularProgress />

        </div>
    }

    const handleLogout = () => {
        dispatch({ type: "auth/setLogout" });
        window.location.replace("/")
    }

    if (isLoaded) {
        return <Loading />
    } else {
        return (
            <div>
                <section className='p-8 relative' >
                    {pathname != '/login' && (
                        <button className='absolute right-4' onClick={handleLogout} >
                            <LogoutIcon />
                        </button>
                    )}
                    <Outlet />
                </section>

                {/* <footer className=" bg-gray-400 transition delay-700 m-0 p-2" >
                    <a href="https://www.baezeni.com/" target='_blank' className='flex justify-center items-end'>
                        <h6 className='text-white' >Powered by Baezeni</h6>
                    </a>
                </footer> */}
            </div>
            // <section className=' h-screen p-8 bg-gradient-to-r from-[#b296ee] to-[#73739B]' >
        )
    }
}

export default Layout
