import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { redirect } from "react-router-dom";

export default function AuthProvider({ children }) {
    const dispatch = useDispatch();
    const isAuth = localStorage.getItem('PIC') == null ? false : true;
    useEffect(() => {
        if (isAuth) {
            dispatch({ type: "auth/setAuthenticated" });
        }
    });

    return children;
}
